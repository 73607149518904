exports.components = {
  "component---src-pages-contact-support-js": () => import("./../../../src/pages/contact-support.js" /* webpackChunkName: "component---src-pages-contact-support-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/not-found.js" /* webpackChunkName: "component---src-pages-not-found-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-subcategories-js": () => import("./../../../src/templates/subcategories.js" /* webpackChunkName: "component---src-templates-subcategories-js" */)
}

